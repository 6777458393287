import { render, staticRenderFns } from "./SummaryView.vue?vue&type=template&id=2337573e&scoped=true&"
import script from "./SummaryView.vue?vue&type=script&lang=js&"
export * from "./SummaryView.vue?vue&type=script&lang=js&"
import style0 from "./SummaryView.vue?vue&type=style&index=0&id=2337573e&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2337573e",
  null
  
)

export default component.exports