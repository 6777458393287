<template>
    <div>
        <!-- Top Page Information -->
        <div class="page-info-top"></div>

        <ModalUnsavedTitleGroupingChanges
            v-model="showModal"
            @confirm="( titleOnly ) => titleOnly ? resetTitleGroupingStatus() : persistAllChanges()"
        />

        <!-- Currently Editable Title -->
        <TitleDetail :key="detailReRenderKey"/>

        <!-- Page Footer Bar -->
        <ThePageFooterBar class="detail-queue-footer">
            <!-- Previous -->
            <v-btn
                :disabled="inFlight"
                outlined
                color="primary"
                @click="showPreviousUserTitle()"
            >
                <v-icon>mdi-chevron-left</v-icon>
                Previous
            </v-btn>

            <div id="save-and-done-button">
                <!-- Save -->
                <v-btn
                    :disabled="inFlight"
                    :loading="modifyTitleInFlight"
                    class="button"
                    color="primary"
                    @click="validateGroupingStatusSaved( saveTitleModifications )"
                >
                    Save
                </v-btn>

                <!--Mark as Done -->
                <v-btn
                    :disabled="inFlight"
                    :loading="completeTitleInFlight"
                    class="button"
                    color="primary"
                    @click="validateGroupingStatusSaved( markTitleAsDone )"
                >
                    Mark as Done
                </v-btn>
            </div>
            <!-- Next -->
            <v-btn
                :disabled="inFlight"
                outlined
                color="primary"
                @click="showNextUserTitle()"
            >
                Next
                <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
        </ThePageFooterBar>
    </div>
</template>

<script>
// Vuex
import {
    mapActions, mapGetters, mapState,
} from 'vuex';

// Utils
import FormatUtil from '@/utils/FormatUtil';

// Components
import ThePageFooterBar from '@/components/ThePageFooterBar';
import ModalUnsavedTitleGroupingChanges from '@/views/common/ModalUnsavedTitleGroupingChanges';
import TitleDetail from './detailView/TitleDetail';

export default {
    name: 'DetailView',
    components: {
        ThePageFooterBar,
        ModalUnsavedTitleGroupingChanges,
        TitleDetail,
    },
    data() {
        return {
            modifyTitleInFlight: false,
            completeTitleInFlight: false,
            detailReRenderKey: 1,

            showModal: false,
            callback: null,
        };
    },
    computed: {
        ...mapGetters( {
            userTitleListLength: 'Title/Queue/titleCount',
            hasTitleModifications: 'Title/Queue/hasTitleModifications',
        } ),
        ...mapState( {
            currentTitle: ( state ) => state.currentTitle,
            userTitleList: ( state ) => state.Title.Queue.list,
            titleModifications: ( state ) => state.Title.Queue.titleModifications,
            statusModified: ( state ) => state.TitleGroup.statusModified,
        } ),

        /** @returns { Boolean } */
        inFlight() {
            return this.modifyTitleInFlight || this.completeTitleInFlight;
        },

        /** @returns { Number } */
        indexOfCurrentTitle() {
            return this.userTitleList
                .map( ( title ) => title.id )
                .indexOf( this.currentTitle.id );
        },
        /** @returns { Boolean } */
        canGoToPreviousTitle() {
            return this.indexOfCurrentTitle > 0;
        },
        /** @returns { Boolean } */
        canGoToNextTitle() {
            return this.indexOfCurrentTitle < ( this.userTitleListLength - 1 );
        },
    },
    watch: {
        $route() {
            this.detailReRenderKey += 1;
        },
    },
    beforeMount() {
        window.addEventListener( 'beforeunload', this.preventNav );
        this.$once( 'hook:beforeDestroy', () => {
            window.removeEventListener( 'beforeunload', this.preventNav );
        } );
    },
    methods: {
        ...mapActions( {
            modifyQueueTitle: 'Title/Queue/modifyQueueTitle',
            completeTitle: 'Title/Queue/completeTitle',
            refreshTitleGroupStatus: 'TitleGroup/refreshTitleGroupStatus',
            updateGroupStatus: 'TitleGroup/updateGroupStatus',
        } ),

        // This function prevents the browser from navigating while editing.
        preventNav( event ) {
            if ( !this.hasTitleModifications && !this.statusModified ) return;
            event.preventDefault();
            // eslint-disable-next-line no-param-reassign
            event.returnValue = '';
        },

        showNextUserTitle() {
            if ( this.canGoToNextTitle ) {
                const nextTitle = this.userTitleList[ this.indexOfCurrentTitle + 1 ];
                this.$router.push( {
                    name: 'TitleResearchQueue',
                    params: { productCode: nextTitle.productCode },
                } );
            } else {
                this.$emit( 'showSummary', 'Completed Queue Research!' );
            }
        },
        showPreviousUserTitle() {
            if ( this.canGoToPreviousTitle ) {
                const previousTitle = this.userTitleList[ this.indexOfCurrentTitle - 1 ];
                this.$router.push( {
                    name: 'TitleResearchQueue',
                    params: { productCode: previousTitle.productCode },
                } );
            } else {
                this.$emit( 'showSummary', 'All done for now. <br />(You have beautiful eyes.)' );
            }
        },

        validateGroupingStatusSaved( callback ) {
            if ( this.statusModified ) {
                this.callback = callback;
                this.showModal = true;
            } else {
                callback();
            }
        },
        async resetTitleGroupingStatus() {
            await this.refreshTitleGroupStatus();
            await this.callback();
        },

        async persistAllChanges() {
            await this.updateGroupStatus();
            await this.callback();
        },

        async saveTitleModifications( title = this.titleModifications ) {
            this.modifyTitleInFlight = true;
            try {
                await this.modifyQueueTitle( { title } );
            } catch ( error ) {
                console.log( 'Error in saveTitleModifications: ', error );
            } finally {
                this.modifyTitleInFlight = false;
            }
        },

        async markTitleAsDone() {
            this.completeTitleInFlight = true;
            try {
                // Wait on these two calls to finish before going to the next title
                await this.saveTitleModifications( {
                    ...this.titleModifications,
                    // lastResearchedDate should only be updated when "Marking a title as Done"
                    lastResearchedDate: FormatUtil.epochToDate( new Date().getTime() ),
                } );
                await this.completeTitle( {
                    title: { productCode: this.currentTitle.productCode },
                } );
                this.showNextUserTitle();
            } catch ( error ) {
                console.log( 'Error in markTitleAsDone', error );
            } finally {
                this.completeTitleInFlight = false;
            }
        },
    },
};
</script>
