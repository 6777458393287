<template>
    <div class="sidebar-thin custom-container">
        <TheSidebar/>

        <ThePageHeadingBar
            :backArrow="showDetailView"
            :pageHeading="pageHeading"
            @goBack="goToSearchPage()"
        />

        <section class="main">
            <v-dialog
                v-model="showConfirmationModal"
                persistent
                width="400px"
            >
                <v-card>
                    <v-toolbar
                        color="primary"
                        dark
                    >
                        <h2>Leave without saving?</h2>
                        <v-spacer />
                        <v-btn
                            color="white"
                            icon
                            @click="cancelRouteLeave()"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>

                    <v-card-text class="mt-8">
                        <p>You have unsaved changes. Are you sure you want to leave without saving?</p>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer />
                        <v-btn
                            outlined
                            @click="cancelRouteLeave()"
                        >
                            Cancel
                        </v-btn>
                        <v-btn
                            color="primary"
                            @click="confirmRouteLeave()"
                        >
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- Title Detail View -->
            <DetailView
                v-if="showDetailView"
                @showSummary="showSummaryWithMessage"
            />
            <!-- Title Summary List -->
            <SummaryView
                v-else
                @clickedTitle="title => $router.push( {
                    name: 'TitleResearchQueue',
                    params: { productCode: title.productCode },
                } )"
            />
        </section>
    </div>
</template>

<script>
// Vuex
import { mapGetters, mapMutations, mapState } from 'vuex';

// Components
import ThePageHeadingBar from '@/components/ThePageHeadingBar';

import TheSidebar from '@/views/common/TheSidebar';
import DetailView from './queue/DetailView';
import SummaryView from './queue/SummaryView';

export default {
    name: 'Queue',
    components: {
        ThePageHeadingBar,
        TheSidebar,
        DetailView,
        SummaryView,
    },
    // Handles navigation from detail page to search page
    beforeRouteUpdate( to, from, next ) {
        this.handleReroute( to, from, next );
    },
    props: {
        productCode: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            showDetailView: false,

            message: '',
            to: null,
            showConfirmationModal: false,
        };
    },
    computed: {
        ...mapGetters( {
            hasTitleModifications: 'Title/Queue/hasTitleModifications',
        } ),
        ...mapState( {
            userTitleList: ( state ) => state.Title.Queue.list,
            currentTitle: ( state ) => state.currentTitle,
            statusModified: ( state ) => state.TitleGroup.statusModified,
        } ),
        pageHeading() {
            return this.showDetailView
                ? ( this.currentTitle.description || this.currentTitle.productCode )
                : 'Title Research Queue';
        },
        /** @returns { Boolean } */
        canLeaveRoute() {
            return !this.showDetailView
                || !( this.hasTitleModifications || this.statusModified )
                || !!this.to;
        },
    },
    watch: {
        productCode: {
            handler: 'initialize',
            immediate: true,
        },
    },
    beforeDestroy() {
        this.clearCurrentTitle();
    },
    methods: {
        ...mapMutations( {
            success: 'SET_SUCCESS_NOTIFICATION',
            error: 'SET_ERROR_NOTIFICATION',
            clearCurrentTitle: 'CLEAR_CURRENT_TITLE',
        } ),

        async initialize() {
            this.$store.dispatch( 'GlobalResources/init' );
            if ( this.userTitleList.length === 0 ) {
                try {
                    await this.$store.dispatch( 'Title/Queue/getList' );
                    this.renderPage();
                } catch ( error ) {
                    console.log( 'Error in Queue initialize: ', error );
                }
            } else {
                this.renderPage();
            }
        },
        renderPage() {
            if ( this.productCode ) {
                // detail
                const titles = this.userTitleList.filter( ( userTitle ) => userTitle.productCode === this.productCode );

                if ( titles.length === 1 ) {
                    this.loadTitleDetailView( titles[ 0 ] );
                } else {
                    this.loadTitleSummaryView();
                    if ( titles.length === 0 ) {
                        this.error( `No title with product code ${ this.productCode } is assigned to you.` );
                    } else if ( titles.length > 1 ) {
                        this.error( `Multiple titles with product code ${ this.productCode } are assigned to you.` );
                    }
                }
            } else {
                this.loadTitleSummaryView();
            }
        },
        loadTitleDetailView( titleToShow ) {
            this.showDetailView = true;
            this.$store.dispatch( 'setCurrentTitle', {
                title: titleToShow,
            } );
        },
        loadTitleSummaryView() {
            this.showDetailView = false;
        },

        showSummaryWithMessage( message ) {
            // Message will be shown by handleReroute
            this.message = message;
            this.$router.push( { name: 'TitleResearchQueue' } );
        },
        confirmRouteLeave() {
            this.$router.push( this.to );
            this.to = null;
            this.showConfirmationModal = false;
        },
        cancelRouteLeave() {
            this.to = null;
            this.showConfirmationModal = false;
        },
        handleReroute( to, from, next ) {
            if ( this.canLeaveRoute ) {
                if (this.message) this.success(this.message);
                next();
                this.message = '';
                this.to = null;
            } else {
                this.to = to;
                this.showConfirmationModal = true;
            }
        },
        async goToSearchPage() {
            await this.$store.dispatch( 'Title/Queue/getList' );
            this.$router.push( { name: 'TitleResearchQueue' } );
        },
    },
};
</script>

<style scoped>
main {
    grid-area: main;
}
</style>
