<template>
    <div>
        <!-- Reassignment Modal -->
        <ModalReassignSelectedTitles
            v-if="showModal"
            :selectedTitles="titleResearchListTable.selected"
            @close="hideReassignmentModal"
        />

        <!-- Top Page Information -->
        <div class="page-info-top">
            <h3>{{ userTitleListLength }} Titles</h3>
        </div>

        <!-- Titles Available to Research -->
        <TableBaseFilterable
            class="table-assigned-titles"
            :filter.sync="titleResearchListTable.filter"
            :headers="titleResearchListTable.headers"
            :items="userTitleList"
            :loading="inFlight"
            :noDataText="( inFlight ? 'Searching...' : 'No Titles Assigned to Research' )"
            :selected.sync="titleResearchListTable.selected"
            :sortBy.sync="tableSortBy"
            :sortDesc.sync="tableSortDesc"
            filterLabel="Filter Assigned Titles..."
            cardElevation="3"
            showSelect
            hideAdd
            @click:row="( e, { item } ) => $emit( 'clickedTitle', item )"
            @current-items="( payload ) => updateCurrentItems( payload )"
        >
            <template #[`item.description`]="{item}">
                {{ item.description || 'Not Set' }}
            </template>
            <template #[`item.primarySupplier.name`]="{ item }">
                {{ getPrimarySupplierName( item ) }}
            </template>
            <template #[`item.listPrice`]="{ item }">
                {{ item.listPrice !== null ? FormatUtil.toCurrency( item.listPrice ) : 'Not Set' }}
            </template>
            <template #[`item.shelfDate`]="{ item }">
                {{ item.shelfDate ? FormatUtil.yearMonthDayToSimpleString( item.shelfDate ) : 'Not Set' }}
            </template>
            <template #[`item.sectionStartDate`]="{ item }">
                {{ item.sectionStartDate ? FormatUtil.dateTimeToSimpleString( item.sectionStartDate ) : 'Not Set' }}
            </template>
            <template #[`item.listingCreateDate`]="{ item }">
                {{ item.listingCreateDate ? FormatUtil.dateTimeToSimpleString( item.listingCreateDate ) : 'Not Set' }}
            </template>
            <template #[`item.lastResearchedDate`]="{ item }">
                {{ item.lastResearchedDate ? FormatUtil.dateTimeToSimpleString( item.lastResearchedDate ) : 'Never Researched' }}
            </template>
        </TableBaseFilterable>

        <!-- Page Footer Bar -->
        <ThePageFooterBar>
            <div class="footer-contents">
                <!-- Unassign Selected Books Button -->
                <ClButton
                    :disabled="inFlight || !hasSelectedItems"
                    color="primary"
                    @click="unassignSelectedTitles()"
                >
                    Unassign
                </ClButton>

                <!-- Reassign Selected Books Button -->
                <ClButton
                    :disabled="inFlight || !hasSelectedItems"
                    outlined
                    type="secondary"
                    @click="displayReassignmentModal()"
                >
                    Reassign
                </ClButton>
            </div>
        </ThePageFooterBar>
    </div>
</template>

<script>
// Vuex
import { mapGetters, mapState } from 'vuex';

// Utils
import FormatUtil from '@/utils/FormatUtil';

// Components
import ThePageFooterBar from '@/components/ThePageFooterBar';
import ModalReassignSelectedTitles from '@/components/modals/ModalReassignSelectedTitles';
import TableBaseFilterable from '@/components/base/table/TableBaseFilterable';

export default {
    name: 'SummaryView',
    components: {
        ThePageFooterBar,
        ModalReassignSelectedTitles,
        TableBaseFilterable,
    },
    data() {
        return {
            titleResearchListTable: {
                selected: [],
                filter: '',
                headers: [
                    {
                        text: 'EAN',
                        value: 'productCode',
                    },
                    {
                        text: 'Title',
                        value: 'description',
                    },
                    {
                        text: 'Primary Supplier',
                        value: 'primarySupplier.name',
                    },
                    {
                        text: 'List Price',
                        align: 'end',
                        value: 'listPrice',
                    },
                    {
                        text: 'Shelf Date',
                        value: 'shelfDate',
                    },
                    {
                        text: 'Section Start Date',
                        value: 'sectionStartDate',
                    },
                    {
                        text: 'Listing Create Date',
                        value: 'listingCreateDate',
                    },
                    {
                        text: 'Last Researched Date',
                        value: 'lastResearchedDate',
                    },
                ],
            },
            showModal: false,
            FormatUtil,
        };
    },
    computed: {
        ...mapGetters( {
            userTitleListLength: 'Title/Queue/titleCount',
        } ),
        ...mapState( {
            inFlight: ( state ) => state.Title.Queue.inFlight,
            userTitleList: ( state ) => state.Title.Queue.list,
        } ),
        /** @returns { Boolean } */
        hasSelectedItems() {
            return this.titleResearchListTable.selected.length > 0;
        },
        tableSortBy: {
            get() {
                return this.$store.state.Title.Queue.table.sortBy;
            },
            set( value ) {
                this.$store.commit( 'Title/Queue/SET_TABLE_SORT_BY', { sortBy: value } );
            },
        },
        tableSortDesc: {
            get() {
                return this.$store.state.Title.Queue.table.sortDesc;
            },
            set( value ) {
                this.$store.commit( 'Title/Queue/SET_TABLE_SORT_DESC', { sortDesc: value } );
            },
        },
    },
    methods: {
        /** @returns { String } */
        getPrimarySupplierName( item ) {
            return item?.primarySupplier?.name ?? 'Not Set';
        },

        displayReassignmentModal() {
            this.showModal = true;
        },
        hideReassignmentModal() {
            this.showModal = false;
        },
        async unassignSelectedTitles() {
            try {
                await this.$store.dispatch( 'Title/Assignment/unassignTitleList', {
                    titleList: this.titleResearchListTable.selected,
                } );
                this.getTitlesInQueue();
                this.titleResearchListTable.selected = [];
            } catch ( error ) {
                console.log( 'Error in unassignSelectedTitles: ', error );
            }
        },
        async getTitlesInQueue() {
            await this.$store.dispatch( 'Title/Queue/getList' );
        },
        /** Update our list of items in state if the table's items are in a different order */
        updateCurrentItems( items ) {
            /** @type { String } */
            const existingItemIds = this.userTitleList.map( ( item ) => item.id ).join( '' );
            /** @type { String } */
            const incomingItemIds = items.map( ( item ) => item.id ).join( '' );

            if ( existingItemIds !== incomingItemIds ) {
                this.$store.commit( 'Title/Queue/SET_LIST', { list: items } );
            }
        },
    },
};
</script>

<style scoped lang="scss">
.table-assigned-titles {
    /* ensures table content won't be hidden by bottom bar */
    margin-bottom: 7rem;
}
.footer-contents {
    * {
        margin-right: 1rem;
    }
}
</style>
