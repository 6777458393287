<template>
    <div class="sub-container">
        <main>
            <ModifyTitle
                class="title-data box-shadow grid-item"
                showViewAllListingsLink
                v-bind="currentTitle"
                @changes="updateTitleModifications"
                @removal="removeTitleModifications"
            />

            <DigitalPrices
                id="digital-pricing"
                :productCode="currentTitle.productCode"
                class="box-shadow"
            />

            <SlingshotLibraries
                :selected-libraries="slingshotLibraries"
                :titleIsbn="currentTitle.productCode"
                class="slingshot-libraries grid-item box-shadow"
            />
            <VendorLibraries
                :selected-libraries="vendorLibraries"
                :titleIsbn="currentTitle.productCode"
                class="vendor-libraries grid-item box-shadow"
            />

            <!-- Inclusive Access Pricing -->
            <InclusiveAccessPrices
                id="ia-pricing"
                :title="currentTitle"
            />
        </main>
    </div>
</template>

<script>
// Vuex
import { mapActions, mapState } from 'vuex';

// Components
import DigitalPrices from '@/views/common/DigitalPrices';
import InclusiveAccessPrices from '@/views/common/InclusiveAccessPrices';
import ModifyTitle from '@/views/common/ModifyTitle';
import SlingshotLibraries from '@/components/multioption/SlingshotLibraries';
import VendorLibraries from '@/components/multioption/VendorLibraries';

export default {
    name: 'TitleDetail',
    components: {
        VendorLibraries,
        SlingshotLibraries,
        DigitalPrices,
        InclusiveAccessPrices,
        ModifyTitle,
    },
    computed: {
        slingshotLibraries() {
            return this.selectedLibraries.filter(({ manuallyManaged }) => manuallyManaged);
        },
        vendorLibraries() {
            return this.selectedLibraries.filter(({ manuallyManaged }) => !manuallyManaged);
        },
        ...mapState( {
            currentTitle: ( state ) => state.currentTitle,
            selectedLibraries: ( state ) => state.Title.LibraryManagement.selectedLibraries,
        } ),
    },
    watch: {
        currentTitle: {
            handler: 'initializeTitleModifications',
            immediate: true,
        },
    },
    beforeMount() {
        this.initializeGlobalResources();
        this.fetchSelectedLibraries(this.currentTitle.productCode);
    },
    methods: {
        ...mapActions({
            initializeGlobalResources: 'GlobalResources/init',
            initializeTitleModifications: 'Title/Queue/initializeTitleModifications',
            updateTitleModifications: 'Title/Queue/updateTitleModifications',
            removeTitleModifications: 'Title/Queue/removeTitleModifications',
            fetchSelectedLibraries: 'Title/LibraryManagement/fetchSelectedLibraries',
        }),
    },
};
</script>

<style lang="scss" scoped>
.sub-container {
    ol, ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    h2 {
        font-variant-caps: all-small-caps;
    }

    main {
        display: grid;
        grid-template:
            "title-data digital-pricing"
            "title-data slingshot-libraries"
            "title-data vendor-libraries"
            "ia-pricing ia-pricing"
            /1fr 1fr;
        grid-gap: 20px;
        grid-column-gap: 1%;
        margin-bottom: 10rem;
    }

    .grid-item {
        padding: 1rem;
    }

    .title-data {
        grid-area: title-data;
    }

    #digital-pricing {
        grid-area: digital-pricing;
    }

    #ia-pricing {
        grid-area: ia-pricing;
    }

    &__title {
        &--details {
            display: flex;
            justify-content: left;

            .isbn, .book-title, .supplier {
                margin-right: 3rem;
            }
        }

        h2 {
            display: inline-block;
        }
    }
}
</style>
